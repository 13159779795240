import { useTranslation, WidgetProps } from '@wix/yoshi-flow-editor';
import React, { FC } from 'react';

export type ControllerProps = {};

const Widget: FC<WidgetProps<ControllerProps>> = () => {
  const { t } = useTranslation();

  return <div>{t('app.widget.title')}</div>;
};

export default Widget;
